import React from 'react';
import { Flex } from '@fuji-ui/core';

export const FeatureBlock = (props) => {
  return (
    <Flex
      as="section"
      alignItems={['stretch', null, 'center', null]}
      {...props}
    />
  );
};
