import React from 'react';
import { navigate } from 'gatsby';
import { Typography as T, Box, Flex, Button } from '@fuji-ui/core';
import { Wrapper } from '../components/layout';
import { FeatureBlock } from '../components/block';
import teachingSvg from '../images/teaching.svg';
import ideaSvg from '../images/idea.svg';
import planSvg from '../images/plan.svg';
import dynamicFormSvg from '../images/dynamic-form.svg';
import paymentSvg from '../images/payment.svg';
import wave from '../images/wave.svg';
import waveEnd from '../images/wave-end.svg';
import SEO from '../components/seo';
import styled from 'styled-components';

const FrontBlock = styled(Box)`
  background: url("${wave}") no-repeat bottom;
`;

const EndBlock = styled(Box)`
  background: url("${waveEnd}") no-repeat top;
`;

const Hero = (props) => (
  <Box {...props}>
    <Wrapper pr="1rem !important">{props.children}</Wrapper>
  </Box>
);

const Tagline = (props) => (
  <T.H1
    fontSize={['xl', 'xxl', null, '3.5rem']}
    lineHeight={[1.15, null, 1.05, null]}
    {...props}
  />
);

const Index = (props) => {
  const toRequest = () => navigate('/request');

  return (
    <>
      <SEO lang="en" title="The cloud enrolment platform" />
      <Hero mt="s">
        <Flex
          alignItems={['stretch', null, 'center', null]}
          flexDirection={['column', null, 'row', null]}
          maxWidth="1080px"
          m="auto"
        >
          <Box m="m" flex={2}>
            <Tagline>Scale up your Enrolment</Tagline>
            <T.P>
              <strong>Enlist</strong> is a customizable enrolment system that
              helps institutes to collect applications and payment at one stop.
            </T.P>
            <Button mt="m" size="l" onClick={toRequest}>
              Learn more
            </Button>
          </Box>
          <Box m="m" flex={3} textAlign="center">
            <img src={teachingSvg} width="100%" alt="" />
          </Box>
        </Flex>
      </Hero>
      <FrontBlock pt="xl">
        <Wrapper>
          <Box
            as="section"
            m="auto"
            textAlign="center"
            maxWidth={[null, 720, 720, null]}
          >
            <T.H1>
              Collect application and get payment done at the same time.
            </T.H1>
            <T.P>
              An essential part of enrolment is to make it a one-stop-shop, so
              that no extra manual job dragging the user on-boarding journey and
              user could find it very simple to complete it. Enlist acquires
              customers quick by making it remotely accomplishable. Whether
              you’re a school principal, a private lesson teacher, or an event
              organizer, Enlist has you covered.
            </T.P>
          </Box>
        </Wrapper>
      </FrontBlock>
      <Box bg="#e8f4ef" pt="xl">
        <Wrapper>
          {/* <Box
            as="section"
            m="auto"
            textAlign="center"
            maxWidth={[null, 720, 720, null]}
          >
            <T.H1>
              Collect application and get payment done at the same time.
            </T.H1>
            <T.P>
              An essential part of enrolment is to make it a one-stop-shop, so
              that no extra manual job dragging the user on-boarding journey and
              user could find it very simple to complete it. Enlist acquires
              customers quick by making it remotely accomplishable. Whether
              you’re a school principal, a private lesson teacher, or an event
              organizer, Enlist has you covered.
            </T.P>
          </Box> */}
          <FeatureBlock mt="m" flexDirection={['column', null, 'row', null]}>
            <Box m="m" flex={1}>
              <img src={planSvg} width="100%" alt="" />
            </Box>
            <Box m="m" flex={1}>
              <T.H2>Customizable application form</T.H2>
              <T.P>
                You may design and customize application forms to collect the
                required information that suit your service.
              </T.P>
            </Box>
          </FeatureBlock>
          <FeatureBlock flexDirection={['column', null, 'row-reverse', null]}>
            <Box m="m" flex={1}>
              <img src={dynamicFormSvg} width="100%" alt="" />
            </Box>
            <Box m="m" flex={1}>
              <T.H2>Dynamic form options</T.H2>
              <T.P>
                Display dynamic form field values base on what the user has
                selected. For example, to display the available time slots base
                on the class one has chosen.
              </T.P>
            </Box>
          </FeatureBlock>
          <FeatureBlock flexDirection={['column', null, 'row', null]}>
            <Box m="m" flex={1}>
              <img src={paymentSvg} width="100%" alt="" />
            </Box>
            <Box m="m" flex={1}>
              <T.H2>Pay. Submit. Done</T.H2>
              <T.P>
                Set fees on per-course basis and users could e-pay it when
                submitting the application. Enlist supports payment through
                major credit cards, Alipay and FPS.
              </T.P>
            </Box>
          </FeatureBlock>
          <FeatureBlock flexDirection={['column', null, 'row-reverse', null]}>
            <Box m="m" flex={1}>
              <img src={ideaSvg} width="100%" alt="" />
            </Box>
            <Box m="m" flex={1}>
              <T.H2>Overview enrolment status</T.H2>
              <T.P>
                Both you and the applicants can check the latest enrolment
                status. Enlist also provides the enrolment report that summarize
                all the submitted applications.
              </T.P>
            </Box>
          </FeatureBlock>
        </Wrapper>
      </Box>
      <EndBlock textAlign="center" pt="200px" pb="xl">
        <Wrapper>
          <Box m="auto" maxWidth={[null, 720, 720, null]}>
            <T.H2>
              We're offering special discount to schools and non-profit
              entities.
            </T.H2>
            <T.P>
              Let us know if you're interested and get up to 50% discount by
              today.
            </T.P>
            <Button mt="m" size="l" onClick={toRequest}>
              Contact us
            </Button>
          </Box>
        </Wrapper>
      </EndBlock>
    </>
  );
};

export default Index;
